// import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const AppContexts = () => {
	const route = useRoutes(routes);

	return (
		<Provider store={Store}>
			<SettingsProvider>
				<MatxTheme>
					<AuthProvider>{route}</AuthProvider>
				</MatxTheme>
			</SettingsProvider>
		</Provider>
	);
};

export default AppContexts;
