const reducer = (state, action) => {
	switch (action.type) {
		case 'INIT': {
			const { isAuthenticated, user } = action.payload

			return {
				...state,
				isAuthenticated,
				isInitialised: true,
				user,
			}
		}
		case 'LOGIN': {
			const { user } = action.payload

			return {
				...state,
				isAuthenticated: true,
				user,
			}
		}
		case 'LOGOUT': {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			}
		}
		case 'REGISTER': {
			const { user } = action.payload

			return {
				...state,
				isAuthenticated: true,
				user,
			}
		}
		default: {
			return { ...state }
		}
	}
};

export default reducer;
