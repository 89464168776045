import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const SignIn = Loadable(lazy(() => import('./SignIn')));
// const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));

const authRoutes = [
	{ path: '/signin', element: <SignIn /> },
	{ path: '/forgot-password', element: <ForgotPassword /> },
	{ path: '/404', element: <NotFound /> },
];

export default authRoutes;