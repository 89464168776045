import { Navigate, Outlet } from 'react-router-dom';

import AuthGuard from './auth/AuthGuard';
import UnauthGuard from './auth/UnauthGuard';
import chartsRoute from './views/charts/ChartsRoute';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import userRoutes from './views/user/UserRoutes';
import applicationRoutes from './views/application/ApplicationRoutes';
import stateInterestRoutes from './views/states-interest/StateInterestRoutes';
// import materialRoutes from './views/material-kit/MaterialRoutes';
import NotFound from './views/auth/NotFound';
import authRoutes from './views/auth/authRoutes';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
	{
		element: (
			<AuthGuard>
				<MatxLayout />
			</AuthGuard>
		),
		children: [...dashboardRoutes, ...chartsRoute, ...userRoutes, ...applicationRoutes, ...stateInterestRoutes],
	},
	{
		element: (
			<UnauthGuard><Outlet /></UnauthGuard>
		),
		children: [...authRoutes],
	},
	{ path: '/', element: <Navigate to="dashboard" /> },
	{ path: '*', element: <NotFound /> },
];

export default routes;
