import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/styled-engine';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import AppContext from './AppContext';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
	return (
		<ErrorBoundary className="App">
			<StyledEngineProvider injectFirst>
				<BrowserRouter>
					<CssBaseline />
					<AppContext />
				</BrowserRouter>
			</StyledEngineProvider>
		</ErrorBoundary>
	);
};

export default App;
