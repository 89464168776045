import Loadable from '../../components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const StatesInterest = Loadable(lazy(() => import('./states-interest')));
const StatesInterestDetails = Loadable(lazy(() => import('./states-interest-details')));

const stateRoutes = [
  { path: '/interest', element: <StatesInterest />, auth: authRoles.admin },
  { path: '/interest/:id', element: <StatesInterestDetails />, auth: authRoles.admin },
];

export default stateRoutes;
