export const navigations = [
	{ name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },
	// { label: 'PAGES', type: 'label' },
	{ name: 'Users', path: '/users', icon: 'group' },
	{ name: 'Applications', path: '/applications', icon: 'article' },
	{ name: 'States Interest', path: '/interest', icon: 'percent' },
	/* {
		name: 'Session/Auth',
		icon: 'security',
		badge: { value: '30+', color: 'secondary' },
		children: [
			{ name: 'Sign in', iconText: 'SI', path: '/session/signin' },
			{ name: 'Sign up', iconText: 'SU', path: '/session/signup' },
			{ name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
			{ name: 'Error', iconText: '404', path: '/session/404' },
		],
	}, */
	/* {
		name: 'Charts',
		icon: 'trending_up',
		children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
	}, */
	/* {
		name: 'Documentation',
		icon: 'launch',
		type: 'extLink',
		path: 'http://demos.ui-lib.com/matx-react-doc/',
	}, */
];
