import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const UnauthGuard = ({ children }) => {
  let {
    isAuthenticated,
    // user
  } = useAuth();
  const { pathname } = useLocation();

  let authenticated = isAuthenticated;

  if (authenticated) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/" state={{ from: pathname }} replace />;
	}
  return children;
};

export default UnauthGuard;
