import Loadable from '../../components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Applications = Loadable(lazy(() => import('./applications')));
const ApplicationDetails = Loadable(lazy(() => import('./application-details')));

const applicationRoutes = [
  { path: '/applications', element: <Applications />, auth: authRoles.admin },
  { path: '/applications/:id', element: <ApplicationDetails />, auth: authRoles.admin },
];

export default applicationRoutes;
