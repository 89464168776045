import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { MatxLoading } from '../components';
import reducer from './AuthReducer';
import axiosApi from '../services/axiosApi';
import { useNavigate } from 'react-router-dom';

const initialState = {
	isAuthenticated: false,
	isInitialised: false,
	user: null,
};

export const isValidToken = (accessToken) => {
	if (!accessToken) {
		return false;
	}

	const decodedToken = jwtDecode(accessToken);
	const currentTime = Date.now() / 1000;
	return decodedToken.exp > currentTime;
}

const setSession = (accessToken) => {
	/* if (accessToken) {
		localStorage.setItem('accessToken', accessToken)
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
	} else {
		localStorage.removeItem('accessToken')
		delete axios.defaults.headers.common.Authorization
	} */
};

const AuthContext = createContext({
	...initialState,
	method: 'JWT',
	login: () => Promise.resolve(),
	logout: () => { },
	// register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const navigate = useNavigate();

	/**
	 * To login admin
	 * 
	 * @param {String} email 
	 * @param {String} password 
	 */
	const login = async (email, password) => {
		const response = await axiosApi.post('/signin', { email, password });
		if (response.data.success && response.data.data) {
			const { userData } = response.data.data;
			// setSession(accessToken)
	
			dispatch({
				type: 'LOGIN',
				payload: {
					user: userData,
				},
			});
		}
	};

	/* const register = async (email, username, password) => {
		const response = await axiosApi.post('/api/auth/register', {
			email,
			username,
			password,
		})

		const { accessToken, user } = response.data

		setSession(accessToken)

		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		})
	}; */

	const logout = () => {
		setSession(null);
		localStorage.clear();
		dispatch({ type: 'LOGOUT' })
		navigate('/', { replace: true })
	};

	useEffect(() => {
		(async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken')
				const user = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {};
				// console.log("accessToken : ", accessToken);
				if (accessToken && isValidToken(accessToken)) {
					setSession(accessToken)
					// const response = await axiosApi.get('/api/auth/profile')
					// const { user } = response.data
					// const user = {};

					dispatch({
						type: 'INIT',
						payload: {
							isAuthenticated: true,
							user,
						},
					})
				} else {
					dispatch({
						type: 'INIT',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					})
				}
			} catch (err) {
				console.error(err)
				dispatch({
					type: 'INIT',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				})
			}
		})();
	}, []);

	if (!state.isInitialised) {
		return <MatxLoading />
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'JWT',
				login,
				logout,
				// register,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContext;
