import Loadable from '../../components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Users = Loadable(lazy(() => import('./users')));
const UserDetails = Loadable(lazy(() => import('./user-details')));

const userRoutes = [
  { path: '/users', element: <Users />, auth: authRoles.admin },
  { path: '/users/:id', element: <UserDetails />, auth: authRoles.admin },
];

export default userRoutes;
