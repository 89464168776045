import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import CloseIcon from '@mui/icons-material/Close';

import './ApproveRejectDialogStyle.scss';

const lenderSchema = {
	lenderName: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 255,
		}
	},
	lenderPhone: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 255,
		}
	},
	lenderEmail: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 255,
		}
	}
};

const noteSchema = {
	note: {
		presence: { allowEmpty: false, message: 'is required' },
	},
};

const ApproveRejectDialog = ({ open, type, onClose }) => {

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});

  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

	useEffect(() => {
		setFormState(formState => ({
			...formState,
			values: {},
			touched: {}
		}));
	}, [open]);

	useEffect(() => {
		const schema = type === 'approved' ? { ...lenderSchema, ...noteSchema } : noteSchema;
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
		...formState,
		isValid: errors ? false : true,
		errors: errors || {}
		}));
	}, [type, formState.values]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
	};
	
	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	const handleSave = () => {
		if (formState.isValid) {
			onClose(formState.values);
		}
	};

	return (
		<Dialog
			className="approve-reject-dialog"
			fullWidth
			fullScreen={fullScreen}
			open={open}
			onClose={() => onClose()}
		>
			<AppBar position="static" className="dialog-header" elevation={0}>
				<DialogTitle>{type === 'approved' ? 'Approval' : 'Rejection' } Confirmation</DialogTitle>
				<IconButton aria-label="delete" onClick={() => onClose()} color="inherit">
					<CloseIcon />
				</IconButton>
			</AppBar>
			{/* <DialogTitle>{type}</DialogTitle> */}
			<DialogContent>
				<form>
					<Grid container spacing={3}>
						{type === 'approved' &&
							<>
								<Grid item md={6} xs={12}>
									<TextField
										error={hasError('lenderName')}
										fullWidth
										helperText={
											hasError('lenderName') ? formState.errors.lenderName[0] : null
										}
										label="Lender's Name"
										name="lenderName"
										onChange={handleChange}
										type="text"
										value={formState.values.lenderName || ''}
										variant="outlined"
										autoComplete="off"
										required
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={hasError('lenderPhone')}
										fullWidth
										helperText={
											hasError('lenderPhone') ? formState.errors.lenderPhone[0] : null
										}
										label="Lender's Phone"
										name="lenderPhone"
										onChange={handleChange}
										type="text"
										value={formState.values.lenderPhone || ''}
										variant="outlined"
										autoComplete="off"
										required
									/>
								</Grid>
								<Grid item md={12} xs={12}>
									<TextField
										error={hasError('lenderEmail')}
										fullWidth
										helperText={
											hasError('lenderEmail') ? formState.errors.lenderEmail[0] : null
										}
										label="Lender's email"
										name="lenderEmail"
										onChange={handleChange}
										type="email"
										value={formState.values.lenderEmail || ''}
										variant="outlined"
										autoComplete="off"
										required
									/>
								</Grid>
							</>
						}
						
						<Grid item md={12} xs={12}>
							<TextField
								error={hasError('note')}
								fullWidth
								helperText={
									hasError('note') ? formState.errors.note[0] : null
								}
								label="Note (Remark)"
								name="note"
								onChange={handleChange}
								type="text"
								value={formState.values.note || ''}
								variant="outlined"
								autoComplete="off"
								multiline
								minRows={4}
								required
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
			<DialogActions className='dialog-action'>
				<Button disabled={!formState.isValid} onClick={handleSave}>Submit</Button>
			</DialogActions>
		</Dialog>
	);

};

ApproveRejectDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ApproveRejectDialog;